.main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.puzzle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.puzzle-grid {
  display: grid;
  gap: 2px;
  width: 500px;
  height: 500px;
  margin-bottom: 20px;
}

.puzzle-grid-cell {
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.puzzle-piece {
  cursor: pointer;
  object-fit: cover;
}

.puzzle-pieces {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 500px;
  justify-content: center;
}

.controls {
  margin-bottom: 20px;
}

.completed-image {
  width: 500px;
  height: 500px;
}
